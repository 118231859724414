<template>
  <div class="home" v-if="show">
    <!-- 导航栏 -->
    <Header class="head" :wwwUrl="wwwUrl" @goAmount="goAmount" @goMy="goMy" />
    <main style="margin-top: 70px">
      <!-- 轮播图 -->
      <Carousel :wwwUrl="wwwUrl" />
      <!-- 数据 -->
      <Amount />
      <!-- 介绍 -->
      <Introduce class="one" />
      <!-- 首页案例 -->
      <HomeCase />
      <!-- 地图 -->
      <Map />
      <!-- 开通 -->
      <Opening :wwwUrl="wwwUrl" />
      <!-- 官方信息 -->
      <Official />
      <!-- 版权 -->
      <Copyright class="my" />
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Carousel from './components/Carousel'
import Amount from './components/Amount'
import Introduce from './components/Introduce'
import HomeCase from './components/HomeCase'
import Map from './components/Map'
import Opening from './components/Opening'
import Official from './components/Official'
import Copyright from './components/Copyright'
import { getStore, removeStore, removeToken } from '@/storage/index'
import { HOMETIME } from '@/storage/variable'
export default {
  name: 'Home',
  components: {
    Header,
    Carousel,
    Amount,
    Introduce,
    HomeCase,
    Map,
    Opening,
    Official,
    Copyright
  },
  data() {
    return {
      wwwUrl: 'https://www.huodongge.cn',
      show: false //上次访问在7天内 立即跳转模型库
    }
  },
  created() {
    let time = getStore(HOMETIME) || 0
    let newTime = new Date().getTime()
    let state = newTime - time < 7 * 24 * 60 * 60 * 1000
    // let state = newTime - time < 30 * 1000
    if (state) {
      this.$store.dispatch('common/goModelMarket')
    } else {
      this.show = true
      removeToken()
      removeStore(HOMETIME)
    }
    const url = window.location.href

    if (
      // url === 'http://www.growth114.com/' ||
      // url === 'http://www.growth114.com/vuemerchant/#/' ||
      // url === 'http://www.growth114.com/official/#/' ||
      url === 'https://www.huodongge.cn/vuemerchant/#/'
    ) {
      window.location.href = 'https://www.huodongge.cn/official/#/'
    }
    this.$store.dispatch('app/getDomainList', 'www').then(res => {
      if (res) {
        this.wwwUrl = res.url
      }
    })
  },
  methods: {
    goAmount() {
      this.$el.querySelector('.one').scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start'
      })
    },
    goMy() {
      this.$el.querySelector('.my').scrollIntoView({
        behavior: 'smooth' // 平滑过渡
      })
    },
    jump(url) {
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .head {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
  }
}
</style>
